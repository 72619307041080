import React from 'react';
import bg from '../../assets/bg-shape-lightgrey.png';

const Contact = () => (
  <section className="contact" id="contact">
    <div className="contact__bg-shape contact__shape1">
      <img src={bg} alt=" "/>
    </div>

    <div className="contact__bg-shape contact__shape2">
      <img src={bg} alt=" "/>
    </div>

    <div className="wrapper">
      <h3 className="subheading">Don't Be A Stranger!</h3>
      <h2>Get In Touch</h2>

      <form method="POST" action="https://formspree.io/amanda@amandasilberberg.com">
        <div className="contact__form-top">

          <label htmlFor="name" className="sr-only">Name</label>
          <input type="text" id="name" name="name" placeholder="Name" autoComplete="off" className="contact__input" required/>

          <label htmlFor="email" className="sr-only">Email</label>
          <input type="email" id="email" name="email" placeholder="Email" autoComplete="off" className="contact__input" required/>

        </div>

        <label htmlFor="message" className="sr-only">Message</label>
        <textarea name="message" id="message" cols="30" rows="10" placeholder="Message" className="contact__textarea" required></textarea>

        <button className="contact__submit" type="submit" aria-label="Send">Send!</button>
      </form>
    </div>
  </section>
)

export default Contact;