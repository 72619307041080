import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { Controller, Scene } from 'react-scrollmagic';

const Portfolio = () => (
  <StaticQuery
    query={graphql`
      query PortfolioPieces {
        allContentfulPortfolioPiece(sort: { fields: createdAt, order: DESC }) {
          edges {
            node {
              pieceTitle
              pieceSlug
              pieceLink
              pieceTechnologies
              pieceHasCasestudy
              pieceImage {
                fluid(quality: 100, maxWidth: 700) {
                  ...GatsbyContentfulFluid
                }
              }
              pieceDescription {
                pieceDescription
              }
            }
          }
        }
      }
    `}
    render={data => (
      <section className="portfolio" id="portfolio">
        <h2 className="top">Portfolio</h2>
        <h3 className="subheading">A Few of My Favourites</h3>

        <div className="portfolio__pieces portfolio-pieces">
          <div className="wrapper">
            {/* eslint-disable-next-line */}
            {data.allContentfulPortfolioPiece.edges.map((edge, i) => {
              const piece = edge.node
              const pieceHandle = piece.pieceTitle
                .toLowerCase()
                .replace(/ /g, "-")

              if (piece.pieceHasCasestudy && !piece.sideProject) {
                return (
                  <Controller key={i}>
                    <Scene
                      offset="-250"
                      classToggle="portfolio-piece--fade-in"
                      triggerElement={`#${pieceHandle}`}
                    >
                      <div
                        id={pieceHandle}
                        className="portfolio-pieces__piece portfolio-piece"
                      >
                        <a
                          href={piece.pieceLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="portfolio-piece__image-link"
                        >
                          <img
                            src={piece.pieceImage.fluid.src}
                            alt={piece.pieceTitle}
                            className="portfolio-piece__image"
                          />
                        </a>

                        <div className="portfolio-piece__piece-info">
                          <h3 className="portfolio-piece__title">
                            {piece.pieceTitle}
                          </h3>

                          <ul className="portfolio-piece__technologies">
                            {piece.pieceTechnologies.map((technology, i) => {
                              return (
                                <li
                                  className="portfolio-piece__technology"
                                  key={i}
                                >
                                  {technology}
                                </li>
                              )
                            })}
                          </ul>

                          <p className="portfolio-piece__description">
                            {piece.pieceDescription.pieceDescription}
                          </p>

                          <a
                            href={piece.pieceLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="portfolio-piece__link"
                          >
                            View Live
                          </a>
                          <a
                            href={`/${piece.pieceSlug}`}
                            className="portfolio-piece__link"
                          >
                            View Case Study
                          </a>
                        </div>
                      </div>
                    </Scene>
                  </Controller>
                )
              }
            })}
          </div>

          <Link to="/portfolio" className="button button--dark">
            Full Portfolio
          </Link>
        </div>
      </section>
    )}
  />
)

export default Portfolio;