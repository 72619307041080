import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const About = () => (
  <StaticQuery
    // query={graphql`
    //   query BioShortQuery {
    //     allContentfulBio {
    //       edges {
    //         node {
    //           bioImage {
    //             fluid(quality: 100, maxWidth: 700) {
    //               ...GatsbyContentfulFluid
    //             }
    //           }
    //           bioShort {
    //             content {
    //               content {
    //                 value
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // `}
    query={graphql`
      query BioShortQuery {
        allContentfulBio {
          edges {
            node {
              bioImage {
                fluid(quality: 100, maxWidth: 700) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <section className="about-me" id="about-me">
        <div className="wrapper">
          <h3 className="subheading">Get to know</h3>
          <h2>
            A Bit <span>About</span> Me
          </h2>

          {/* <div className="about-me__container">
            <div className="about-me__headshot">
              <Img fluid={data.allContentfulBio.edges[0].node.bioImage.fluid} />
            </div>

            <div className="about-me__bio">
              <p>
                {
                  data.allContentfulBio.edges[0].node.bioShort.content[0]
                    .content[0].value
                }
              </p>

              <Link to="/about" className="button button--open button--light">
                Learn more!
              </Link>
            </div>
          </div> */}
        </div>
      </section>
    )}
  />
)

export default About
