import React from "react"
import Layout from "../components/layout"
import About from "../components/sections/about"
import Portfolio from "../components/sections/portfolio"
import Contact from "../components/sections/contact"
import { graphql } from "gatsby"

export default ({ data }) => {
  const showSplashPage = data.contentfulSplashPageToggle.showSplashPage

  return !showSplashPage ? (
    <div>
      <Layout page="homepage" headerStrings={data}>
        <About />
        <Portfolio />
        <Contact />
      </Layout>
    </div>
  ) : (
    <div>
      <Layout
        page="splash-page"
        splashText={data.contentfulSplashPage}
        skills={data.allContentfulSkillIcon.edges}
        headerStrings={[]}
        showSplashPage={showSplashPage}
      />
    </div>
  )
}

export const query = graphql`
  query HeaderStringsQuery {
    contentfulSplashPageToggle {
      showSplashPage
    }
    allContentfulHeaderTextStrings {
      edges {
        node {
          string
        }
      }
    }
    contentfulSplashPage {
      splashText2
      splashContact
      splashText {
        splashText
      }
    }
    allContentfulSkillIcon(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          deviconClassName
          deviconIconName
        }
      }
    }
  }
`
